import { createRouter, createWebHistory } from 'vue-router';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { VueRouterRoutingProvider } from '@bugsnag/vue-router-performance';
import { useAuthStore } from '@/stores/auth';
import { useTenantStore } from '@/stores/tenant';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/LoginView.vue'),
            meta: {
                title: 'Login',
                translateToken: 'title.login',
                secure: false,
            },
        },
        {
            path: '/sso',
            name: 'sso',
            component: () => import('./views/SsoView.vue'),
            meta: {
                title: 'Login',
                translateToken: 'title.login',
                secure: false,
            },
        },
        {
            path: '/error',
            name: 'error',
            component: () => import('./views/ErrorView.vue'),
            meta: {
                title: 'Error',
                translateToken: 'title.error',
                secure: false,
                error: true,
            },
        },
        {
            path: '/',
            component: () => import('./views/Dashboard.vue'),
            meta: {
                secure: true,
            },
            children: [
                {
                    path: '',
                    name: 'home',
                    component: () => import('./views/home.vue'),
                    meta: {
                        title: 'Home',
                        translateToken: 'title.home',
                        secure: true,
                    },
                },
                {
                    path: '/contacts',
                    name: 'contacts',
                    component: () => import('./views/contacts/index.vue'),
                    meta: {
                        title: 'Contacts',
                        translateToken: 'title.contacts',
                        secure: true,
                    },
                },
                {
                    path: '/contacts/:id',
                    name: 'contact',
                    component: () => import('./views/contacts/view.vue'),
                    meta: {
                        title: 'Contact',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'Contacts',
                                translateToken: 'title.contact',
                                route: { name: 'contacts' },
                            },
                        ],
                        secure: true,
                    },
                },
                {
                    path: '/organisations',
                    name: 'organisations',
                    component: () => import('./views/organisations/index.vue'),
                    meta: {
                        title: 'Organisations',
                        translateToken: 'title.organisations',
                        secure: true,
                    },
                },
                {
                    path: '/organisations/:id',
                    name: 'organisation',
                    component: () => import('./views/organisations/view.vue'),
                    meta: {
                        title: 'Organisation',
                        translateToken: 'title.organisation',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'Organisations',
                                translateToken: 'title.organisations',
                                route: { name: 'organisations' },
                            },
                        ],
                        secure: true,
                    },
                },
                {
                    path: '/organisations/:id/order',
                    name: 'organisation.new_order',
                    component: () => import('./views/organisations/new_order.vue'),
                    meta: {
                        title: 'Organisation - New Order',
                        translateToken: 'title.new_order',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'Organisations',
                                translateToken: 'title.organisations',
                                route: { name: 'organisations' },
                            },
                        ],
                        secure: true,
                    },
                },
                {
                    path: '/organisations/:id/order/:orderId',
                    name: 'organisation.order',
                    component: () => import('./views/organisations/existing_order.vue'),
                    meta: {
                        title: 'Organisation - Order',
                        translateToken: 'title.order',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'Organisations',
                                translateToken: 'title.organisations',
                                route: { name: 'organisations' },
                            },
                        ],
                        secure: true,
                    },
                },
                {
                    path: '/products',
                    name: 'products',
                    component: () => import('./views/products/index.vue'),
                    meta: {
                        title: 'Products',
                        translateToken: 'title.products_services',
                        secure: true,
                    },
                },
                {
                    path: '/products/:id',
                    name: 'product',
                    component: () => import('./views/products/view.vue'),
                    meta: {
                        title: 'Product',
                        translateToken: 'title.product',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'Products',
                                route: { name: 'products' },
                            },
                        ],
                        secure: true,
                    },
                },
                {
                    path: '/memberships',
                    name: 'memberships',
                    component: () => import('./views/memberships/index.vue'),
                    meta: {
                        title: 'Memberships',
                        translateToken: 'title.memberships',
                        secure: true,
                    },
                },
                {
                    path: '/memberships/:id',
                    name: 'membership',
                    component: () => import('./views/memberships/view.vue'),
                    meta: {
                        title: 'Membership',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'Memberships',
                                translateToken: 'title.membership',
                                route: { name: 'memberships' },
                            },
                        ],
                    },
                },
                {
                    path: '/my-company',
                    name: 'my-company',
                    component: () => import('./views/my_company/index.vue'),
                    meta: {
                        title: 'My Company',
                        translateToken: 'title.my_company',
                        secure: true,
                    },
                },
                {
                    path: '/my-company/users',
                    name: 'users',
                    component: () => import('./views/users/index.vue'),
                    meta: {
                        title: 'Users',
                        translateToken: 'title.users',
                        secure: true,
                    },
                },
                {
                    path: '/my-company/users/:id',
                    name: 'user',
                    component: () => import('./views/users/view.vue'),
                    meta: {
                        title: 'User',
                        dynamicTitle: true,
                        breadcrumbs: [
                            {
                                label: 'users',
                                translateToken: 'title.user',
                                route: { name: 'users' },
                            },
                        ],
                        secure: true,
                    },
                },
                {
                    path: '/my-company/custom-fields',
                    name: 'custom-fields',
                    component: () => import('./views/my_company/customFields.vue'),
                    meta: {
                        title: 'Custom Fields',
                        translateToken: 'title.custom_fields',
                        secure: true,
                    },
                },
                {
                    path: '/dev',
                    component: () => import('./views/dev/index.vue'),
                    dev: true,
                    children: [
                        {
                            path: '',
                            name: 'dev',
                            component: () => import('./views/dev/home.vue'),
                            meta: {
                                title: 'Dev',
                                secure: true,
                            },
                        },
                        {
                            path: 'colours',
                            name: 'colours',
                            component: () => import('./views/dev/colours.vue'),
                            meta: {
                                title: 'Colours',
                                breadcrumbs: [
                                    {
                                        label: 'Dev',
                                        route: { name: 'dev' },
                                    },
                                ],
                                secure: true,
                            },
                        },
                        {
                            path: 'typography',
                            name: 'typography',
                            component: () => import('./views/dev/typography.vue'),
                            meta: {
                                title: 'Typography',
                                breadcrumbs: [
                                    {
                                        label: 'Dev',
                                        route: { name: 'dev' },
                                    },
                                ],
                                secure: true,
                            },
                        },
                        {
                            path: 'icons',
                            name: 'icons',
                            component: () => import('./views/dev/icons.vue'),
                            meta: {
                                title: 'Icons',
                                breadcrumbs: [
                                    {
                                        label: 'Dev',
                                        route: { name: 'dev' },
                                    },
                                ],
                                secure: true,
                            },
                        },
                        {
                            path: 'library-components',
                            name: 'library-components',
                            component: () => import('./views/dev/libraryComponents.vue'),
                            meta: {
                                title: 'Library Components',
                                breadcrumbs: [
                                    {
                                        label: 'Dev',
                                        route: { name: 'dev' },
                                    },
                                ],
                                secure: true,
                            },
                        },
                    ],
                },
            ],
        },
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            redirect: { name: 'login' },
            meta: {
                title: '404 Not Found',
                translateToken: 'title.404',
                secure: false,
            },
        },
    ],
});

router.beforeEach((to, from, next) => {
    const store = useAuthStore();
    //Get tenant & user permission
    if (to.meta.secure && !store.user) {
        next({ name: 'login' });
    } else if (!to.meta.secure && store.user && !to.meta.error) {
        next({ name: 'home' });
    } else {
        next();
    }
});

router.afterEach((to) => {
    const authStore = useAuthStore();
    const tenantStore = useTenantStore();
    document.title =
        to.meta.dynamicTitle && authStore.localUserSettings.currentPageTitle
            ? authStore.localUserSettings.currentPageTitle + ' | ' + tenantStore.tenant.name
            : to.meta.title + ' | ' + tenantStore.tenant.name;
});

BugsnagPerformance.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    routingProvider: new VueRouterRoutingProvider(router),
});

export default router;
