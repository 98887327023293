import { defineStore } from 'pinia';
import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';

export const useApiStore = defineStore('api', {
    state: () => ({
        api: wretch(`//tenant.${import.meta.env.VITE_BACKEND_URL}/api`)
            .addon(QueryStringAddon)
            .headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'X-Tenant': window.location.host,
            })
            .errorType('json'),
    }),
    actions: {
        setToken(token) {
            this.api = this.api.auth(`Bearer ${token}`);
        },
        removeToken() {
            this.api = this.api.auth(null);
        },
    },
});
