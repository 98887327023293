import { library, config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { icons } from 'nl-component-library';

// Initialize Font Awesome
export function initializeFontAwesome(app) {
    // Add all necessary icons to the library
    library.add(icons);

    // Set default styling configuration
    config.styleDefault = 'regular';

    // Register the FontAwesomeIcon component globally
    app.component('font-awesome-icon', FontAwesomeIcon);
}
