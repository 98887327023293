import './main.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import App from './App.vue';
import Router from './router';
import i18n from './plugins/i18n';

import { initializeBugsnag } from './plugins/bugsnag';
import { initializePrimeVue } from './plugins/primevue';
import { initializeFontAwesome } from './plugins/fontawesome';

const pinia = createPinia();
const app = createApp(App);

initializeBugsnag(app);
initializePrimeVue(app);
initializeFontAwesome(app);

app.use(Router);
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.use(i18n);
app.mount('#app');
