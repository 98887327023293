import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';

export function initializePrimeVue(app) {
    const nucleusPrimePreset = definePreset(Aura, {
        semantic: {
            primary: {
                50: `color-mix(in srgb, var(--primary) 10%, white)`,
                100: `color-mix(in srgb, var(--primary) 20%, white)`,
                200: `color-mix(in srgb, var(--primary) 35%, white)`,
                300: `color-mix(in srgb, var(--primary) 50%, white)`,
                400: `color-mix(in srgb, var(--primary) 75%, white)`,
                500: `var(--primary)`,
                600: `color-mix(in srgb, var(--primary), black 10%)`,
                700: `color-mix(in srgb, var(--primary), black 20%)`,
                800: `color-mix(in srgb, var(--primary), black 40%)`,
                900: `color-mix(in srgb, var(--primary), black 55%)`,
                950: `color-mix(in srgb, var(--primary), black 70%)`,
            },
        },
        components: {
            menu: {
                colorScheme: {
                    light: {
                        item: {
                            focus: {
                                background: '{primary.50}',
                            },
                        },
                    },
                    dark: {
                        item: {
                            focus: {
                                background: '{primary.950}',
                            },
                        },
                    },
                },
            },
            breadcrumb: {
                background: 'none',
                item: {
                    color: '#fff',
                    icon: {
                        color: '#fff',
                        hover: {
                            color: '{primary.200}',
                        },
                    },
                    hover: {
                        color: '{primary.200}',
                    },
                },
                separator: {
                    color: '#fff',
                },
            },
            progressspinner: {
                colorScheme: {
                    light: {
                        root: {
                            'color.1': '#fff',
                            'color.2': '#fff',
                            'color.3': '#fff',
                            'color.4': '#fff',
                        },
                    },
                    dark: {
                        root: {
                            'color.1': '#fff',
                            'color.2': '#fff',
                            'color.3': '#fff',
                            'color.4': '#fff',
                        },
                    },
                },
            },
            message: {
                colorScheme: {
                    light: {
                        warn: {
                            background: '{orange-50}',
                            color: '{orange.500}',
                            border: {
                                color: '{orange.200}',
                            },
                        },
                    },
                    dark: {
                        warn: {
                            background: 'color-mix(in srgb,{orange-500}, transparent 84%)',
                            color: '{orange.500}',
                            border: {
                                color: 'color-mix(in srgb, {orange-700}, transparent 64%)',
                            },
                        },
                    },
                },
            },
        },
    });

    app.use(PrimeVue, {
        theme: {
            preset: nucleusPrimePreset,
            options: {
                darkModeSelector: '.dark',
                cssLayer: {
                    name: 'primevue',
                    order: 'tailwind-base, primevue, tailwind-utilities',
                },
            },
        },
        locale: {
            dateFormat: 'dd/mm/yy',
        },
    });
    app.use(ToastService);
    app.use(ConfirmationService);
    app.directive('tooltip', Tooltip);
}
